<template>
  <v-col
    cols="12"
    :md="size === 2 ? 6 : size === 3 ? 4 : undefined"
  >
    <v-hover
      v-slot="{ hover }"
      open-delay="100">
      <v-card :elevation="hover ? 12 : 2" class="mx-auto" height="200px"
      >
        <!-- <v-img
          :src="require(`@/assets/articles/${value.hero}`)"
          height="100%"
        >
        </v-img> -->
        <v-card-title><span class="pink--text">{{value.name}}</span>
          <v-spacer></v-spacer>
          <v-chip
            v-show="value.url"
            class="text-uppercase ma-0"
            color="pink"
            dark
            label
            small
            :href="value.url"
            target="_blank"
          >
            {{ $t('MeeTeeMeeNgern1.viewMap') }}
          </v-chip>
        </v-card-title>
        <v-divider></v-divider>
        <!-- <v-card-subtitle>{{value.street_1}}</v-card-subtitle>  -->
        <v-card-text>
          <v-row>
            <v-col cols="12">
              {{value.street_1}}
            </v-col>
            <v-col cols="12">
              {{value.tel}}
            </v-col>
            <!-- <v-col cols="12" align="Right">
              <v-chip
                v-show="value.url"
                class="text-uppercase ma-0"
                color="pink"
                dark
                label
                small
                :href="value.url"
                target="_blank"
              >
                {{ $t('MeeTeeMeeNgern1.viewMap') }}
              </v-chip>
            </v-col> -->
          </v-row>
          <!-- <p>{{value.street_1}}</p>
          <p>{{value.tel}}</p>
          <v-spacer></v-spacer>
          <v-chip
            v-show="value.url"
            class="text-uppercase ma-0"
            color="pink"
            dark
            label
            small
            :href="value.url"
            target="_blank"
          >
            {{ $t('MeeTeeMeeNgern1.viewMap') }}
          </v-chip> -->
        </v-card-text>
        <!-- <v-divider></v-divider> -->
        <!-- <v-card-actions>
          <v-spacer></v-spacer>
          <v-chip
            v-show="value.url"
            class="text-uppercase ma-0"
            color="pink"
            dark
            label
            small
            :href="value.url"
            target="_blank"
          >
            {{ $t('MeeTeeMeeNgern1.viewMap') }}
          </v-chip>
        </v-card-actions> -->
        <!-- <v-chip
          v-show="value.url"
          class="text-uppercase ma-0"
          color="pink"
          dark
          label
          small
          :href="value.url"
          target="_blank"
          absolute right
        >
          {{ $t('MeeTeeMeeNgern1.viewMap') }}
        </v-chip> -->
      </v-card>
    </v-hover>
    <!-- </base-card> -->
  </v-col>
</template>

<script>
  export default {
    name: 'FeedCard',

    props: {
      size: {
        type: Number,
        required: true,
      },
      value: {
        type: Object,
        default: () => ({}),
      },
    },
  }
</script>

<style>
.v-image__image {
  transition: .3s linear;
}
</style>
